@use 'sass:map';
@use '@angular/material' as mat;
@use '@angular/material/theming' as theming;

@mixin typography($config) {
  $typography-config: mat.get-typography-config($config);

  .client-wallet-container {
    .payment-page-heading {
      font-size: mat.font-size($typography-config, title);
    }
  }
}

@mixin theme($theme-or-color-config) {
  $theme: mat.private-legacy-get-theme($theme-or-color-config);
  @include mat.private-check-duplicate-theme-styles($theme, 'client-wallet') {
    $typography: mat.get-typography-config($theme);

    @if $typography != null {
      @include typography($typography);
    }
  }
}
