@use '../../_base/ui-chips-list/chip-list-theme' as chip-list;
@use '../../_base/ui-option/option-theme' as option;
@use '../../_base/ui-selector-multiple/selector-theme' as selector;
@use '../../widgets/ui-client-selector-multiple/client-selector-theme' as client-selector;
@use '../../form/components/form-field/form-field-theme' as form-field;
@use '../../_base/ui-icon/icon-theme' as icon;
@use '../../_base/ui-card/card-theme' as card;
@use '../../_base/ui-badge/badge-theme' as badge;
@use '../../_base/ui-sidenav/ui-sidenav-theme' as sidenav;
@use '../../_base/ui-input-link/input-link-theme' as input-link;
@use '../../_base/ui-stepper/stepper-theme' as stepper;
@use '../../_base/ui-divider/divider-theme' as divider;

// ui-kit base components
@mixin components-themes($theme) {
  @include chip-list.theme($theme);
  @include option.theme($theme);
  @include selector.theme($theme);
  @include client-selector.theme($theme);
  @include form-field.theme($theme);
  @include icon.theme($theme);
  @include card.theme($theme);
  @include badge.theme($theme);
  @include sidenav.theme($theme);
  @include input-link.theme($theme);
  @include stepper.theme($theme);
  @include divider.theme($theme);

  .mat-bottom-sheet-container {
    box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0px rgb(0 0 0 / 14%), 0 1px 3px 0px rgb(0 0 0 / 12%);
  }

  .mat-bottom-sheet-container {
    overflow: inherit !important;
  }

  /* Works on Firefox */
  .app-autocomplete,
  .ui-autocomplete,
  .ui-client-selector,
  .ui-client-selector-panel {
    scrollbar-width: thin;
    scrollbar-color: #b5c6ca #fff;
  }

  /* Works on Chrome, Edge, and Safari */
  .app-autocomplete::-webkit-scrollbar,
  .ui-autocomplete::-webkit-scrollbar,
  .ui-client-selector::-webkit-scrollbar,
  .ui-client-selector-panel::-webkit-scrollbar {
    width: 4px;
  }

  .app-autocomplete::-webkit-scrollbar-track,
  .ui-autocomplete::-webkit-scrollbar-track,
  .ui-client-selector::-webkit-scrollbar-track,
  .ui-client-selector-panel::-webkit-scrollbar-track {
    border-radius: 2.5px;
    background: inherit;
  }

  .app-autocomplete::-webkit-scrollbar-thumb,
  .ui-autocomplete::-webkit-scrollbar-thumb,
  .ui-client-selector::-webkit-scrollbar-thumb,
  .ui-client-selector-panel::-webkit-scrollbar-thumb {
    background-color: #b5c6ca;
    border-radius: 2.5px;
    border: 3px solid inherit;
  }
}
