@use '@angular/material' as mat;
@use '@angular/material/theming' as theming;

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $primary: map-get($config, primary);
  $accent: map-get($config, accent);
  $warn: map-get($config, warn);
  $background: map-get($config, background);
  $foreground: map-get($config, foreground);

  $drawer-background-color: mat.get-color-from-palette($background, background);
  $drawer-container-background-color: mat.get-color-from-palette($background, background);
  $drawer-push-background-color: mat.get-color-from-palette($background, dialog);
  $drawer-side-border: none;

  .mat-drawer-container {
    background-color: $drawer-container-background-color;
  }

  .mat-drawer {
    background-color: $drawer-background-color;

    &.mat-drawer-push {
      background-color: $drawer-push-background-color;
    }
  }

  .mat-drawer-side {
    border: none;
  }

  .mat-drawer-backdrop.mat-drawer-shown {
    $opacity: 0.6;
    $backdrop-color: rgba(255, 255, 255, 0.5);

    @if (type-of($backdrop-color) == color) {
      // We use invert() here to have the darken the background color expected to be used. If the
      // background is light, we use a dark backdrop. If the background is dark,
      // we use a light backdrop.
      background-color: invert($backdrop-color);
    } @else {
      // If we couldn't resolve the backdrop color to a color value, fall back to using
      // `opacity` to make it opaque since its end value could be a solid color.
      background-color: $backdrop-color;
      opacity: $opacity;
    }
  }
}

@mixin typography($config-or-theme) {
}

@mixin theme($theme-or-color-config) {
  $theme: theming.mat-private-legacy-get-theme($theme-or-color-config);
  @include theming.mat-private-check-duplicate-theme-styles($theme, 'mat-sidenav') {
    $color: mat.get-color-config($theme);
    $typography: mat.get-typography-config($theme);

    @if $color != null {
      @include color($color);
    }

    @if $typography != null {
      @include typography($typography);
    }
  }
}
