/*html {
  height: 100%;
}
body {
  min-height: 100%;
}*/
body {
  -webkit-font-smoothing: antialiased; /* Chrome, Safari */
  -moz-osx-font-smoothing: grayscale; /* Firefox */
}

// .row .no-gutters
.row-m0 {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0;
  margin-left: 0;

  > .col,
  > [class*='col-'] {
    padding-right: 0;
    padding-left: 0;
  }
}

.text-nowrap {
  white-space: nowrap;
}

.backdrop-low-z-index {
  z-index: 100 !important;
}

.simple-notification-wrapper {
  z-index: 9999 !important;
}
