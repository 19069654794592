// Mixin taken from https://css-tricks.com/almanac/properties/l/line-clamp/
@mixin line-clamp($lines: 1) {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  overflow: hidden;
  text-overflow: ellipsis;
}

// Concept taken from https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/
@mixin word-break-anywhere {
  overflow-wrap: anywhere;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
}
// Exact version from the link above
@mixin long-word-break {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

@mixin cut-one-line-string {
  @include line-clamp();
  @include long-word-break;

  hyphens: none;
}

@mixin ui-selector() {
  .ui-selector-container {
    width: 100%;

    display: flex;
    align-items: center;

    .ui-chip-list {
      flex: 1;
    }

    .selector-icon {
      flex: 0 0 20px;

      .mat-icon {
        width: 20px;
        height: 20px;
      }
    }
  }

  .ui-client-selector {
    display: flex;
    width: 100%;

    .ui-chip-list {
      .mat-chip-list-wrapper {
        overflow-y: auto;
        overflow-x: hidden;
      }

      .ui-chip {
        border-radius: 4px;
        min-height: 27px;
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }

  .ui-client-selector-panel {
    border-width: 1px;
    border-style: solid;
    box-sizing: border-box;
    border-radius: 0 0 4px 4px;
    width: 100%;
    max-height: 350px;
    overflow-y: scroll;

    .client-option {
      display: flex;
      flex-direction: row;
      max-width: 100%;
      height: 70px;
      align-items: center;
      overflow: visible;

      .client-avatar {
        img {
          height: 40px;
          width: 40px;
          border-radius: 50%;
          vertical-align: middle;
        }
      }

      .client-info {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        padding-left: 0.33em;
        overflow: hidden;
        justify-content: center;

        & > * {
          height: 20px;
          line-height: 20px;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .client-name {
          font-weight: bold;
        }
      }
    }
  }
}
