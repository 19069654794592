$transition-options: 0.2s ease-in-out;

$session-black-color: #32495b;
$session-text-diminished: 12px;
$session-blue-color: #0389a4;

$border-color: #dde7ec;
$light-gray: #8196a7;
$text-color: #32495b;

$sidebar-width-base: 264px;

$bg-badge-color: #fffbec;

$secondary-bg-color: #edf7f9;
