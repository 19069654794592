@import 'scss/lato-font/lato';
/* TODO Remove bootstrap Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
@import 'scss/typography';

@import 'scss/star-rating';
@import 'scss/variables';
@import 'scss/base';
@import 'scss/layout';
@import 'scss/ng-select';

//@import 'scss/dropdown';
@import 'scss/session-provider';
@import 'scss/signup_guide_notification';
@import 'scss/quill';
@import 'scss/quill-snow';
@import 'scss/hamburgers';
@import 'scss/scroll';
@import 'scss/intercom';
@import 'scss/hubspot';
@import 'scss/invitations';
@import 'scss/modals';
@import 'scss/wistia-uploader.css';
//// TODO By some reason it not exportable @import '@siemens/ngx-datatable/assets/icons.css';
@import 'scss/custom-modals/form-quizzes';
@import 'scss/notifications';
// Libraries Overrides
@import 'scss/custom-rules/full-calendar';
@import 'scss/custom-rules/library-buttons';
@import 'scss/custom-rules/tooltip';

@import 'app/modules/ui-kit/_theme/ui-kit.theme';

@import 'node_modules/@awarenow/profi-ui-core/src/assets/styles/themes/main.scss';

@import 'scss/issues/temporary-css-fixes';
@import 'scss/table';

$zindex-modal: 2000;

// ToDo(lihih) move it is into separate scss file
//  (https://github.com/Tinkoff/taiga-ui/blob/main/projects/core/styles/basic/main.less)
html,
body {
  margin: 0 !important;
}

pui-hint-host {
  z-index: 2000;
}

pui-dropdown-box {
  z-index: 1200;
}

input,
button,
select,
textarea {
  font-family: inherit;
  color: inherit;
}

.ng-select.ng-select-opened,
.ng-select.ng-select-focused {
  z-index: 4 !important;
}

.pui-drawer__container {
  height: 100%;
}

.pui-error {
  color: #f10b4d;
}

pui-input-date.ng-invalid.ng-touched {
  .pui-paper[data-pui-host-bordered]::after {
    color: #f10b4d !important;
  }
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

// TODO Move to the Library
.pui-tag_text-overflow {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: var(--pui-line-clamp, 1);
}

.osano_hidden {
  .osano-cm-window {
    display: none;
  }
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

// https://tailwindcss.com/docs/width
.w-full {
  width: 100%;
}

// If hide it frame required to show privacy rules for clients.
.grecaptcha-badge {
  visibility: hidden !important;
}

.hidden-frame {
  width: 1px;
  height: 1px;
  visibility: hidden;
}

a {
  color: #0389a4;
  text-decoration: none;
  cursor: pointer;

  &:active {
    color: inherit;
  }
}

// .modal {
//   z-index: 10556;
// }

// Reset fieldset
legend {
  display: table;
  padding: 0;
}

fieldset {
  border: 0;
  margin: 0;
  min-width: 0;
  padding: 0.01em 0 0 0;
}

body:not(:-moz-handler-blocked) fieldset {
  display: table-cell;
}

@mixin skeleton-animation {
  background-color: #f0f3f5;
  background-image: linear-gradient(90deg, rgba(#dce3e7, 0), rgba(#dce3e7, 0.5), rgba(#dce3e7, 0));
  background-size: 100px 100%;
  background-repeat: no-repeat;
  background-position: left -100px top 0;
  animation: shine 2s ease infinite;

  @keyframes shine {
    25%,
    100% {
      background-position: right -100px top 0;
    }
  }
}

.bg-skeleton {
  background-color: #f0f3f5;
  background-image: linear-gradient(90deg, rgba(#dce3e7, 0), rgba(#dce3e7, 0.5), rgba(#dce3e7, 0));
  background-size: 100px 100%;
  background-repeat: no-repeat;
  background-position: left -100px top 0;
  animation: shine 2s ease infinite;
}

@keyframes shine {
  25%,
  100% {
    background-position: right -100px top 0;
  }
}

.pui-drawer__main {
  overflow-y: scroll;
}

//Bootstrap fixes
.modal {
  // --bs-body-bg: #fff;
  --bs-modal-bg: #fff !important;
}

.form-control {
  background-color: #fff !important;
}

.mat-drawer-side {
  border: none !important;
}

.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm,
.container {
  margin: auto;
}

@media screen and (max-width: 768px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 360px !important;
  }
}

@media screen and (min-width: 768px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 768px !important;
  }
}

@media screen and (min-width: 992px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1440px !important;
  }
}

@media screen and (min-width: 1920px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1920px !important;
  }
}

.modal-backdrop,
.modal {
  z-index: 1000 !important;
}

a,
.btn-link {
  text-decoration: none;
}

// TODO Move to library and test.s
[pui-group] {
  .pui-button[pui-button] {
    background-color: #edf7f9 !important;
    padding-left: 12px;
    padding-right: 12px;
  }
}

// TODO Move to library and test.s
.pui-group > * {
  height: 44px;
}

.modal,
.pui-dialog {
  .pui-button + .pui-button {
    margin-left: 12px !important;
  }
}

img[fill] {
  object-fit: cover;
}
