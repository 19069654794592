@import 'node_modules/@awarenow/profi-ui-core/src/assets/styles/mixins/typography';

$heading-variants: h1, h2, h3, h4, h5, label;

@each $heading in $heading-variants {
  #{$heading}:not(.pui-typography) {
    @include pui-typography($heading);
    margin-bottom: 8px;
  }
}
